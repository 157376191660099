import React from 'react';
import { useParams, Link } from 'react-router-dom'; // For reading route parameters and Link component
import './CoursePage.css'; // Make sure this CSS file is linked correctly

const CoursePage = () => {
  const { courseType } = useParams(); // Get the course type from the URL parameter

  // Define the course content based on the courseType
  const courseContent = {
    nda: {
      title: 'NDA Course',
      overview: 'The NDA exam is one of the most competitive exams in India. Our NDA preparation course covers all the essential topics such as Mathematics, General Knowledge, English, and Science. The course includes in-depth theory, mock tests, and personalized mentoring.',
      whatYouLearn: [
        'Complete preparation for the Mathematics section',
        'English grammar and comprehension skills',
        'General Knowledge, including current affairs, history, geography, physics,chemasrtry and life sciences',
        'Time management and exam strategies',
      ],
      benefits: [
        'Access to expert instructors with having more then 30 years experince',
        'Personalized feedback and progress tracking',
        'Comprehensive study material and practice papers',
        'Mock exams to simulate real NDA test conditions',
        'Dedicated doubt-solving sessions',
      ],
    },
    cds: {
      title: 'CDS Course',
      overview: 'The CDS exam is for candidates aspiring to join the Indian Military Academy. Our course prepares you for the written test as well as the interview.',
      whatYouLearn: [
        'Complete preparation for Mathematics and English',
        'General Knowledge and Current Affairs',
        'Strategy for the written and interview rounds',
      ],
      benefits: [
        'Expert trainers with experience in CDS exams',
        'Practice tests for each section',
        'Personalized mentoring sessions',
        'Focused preparation for the interview phase',
      ],
    },
    navy: {
      title: 'Navy Exam Course',
      overview: 'The Navy exam is for candidates who wish to join the Indian Navy. Our Navy exam preparation covers all aspects of the test, including Mathematics, English, and General Knowledge.',
      whatYouLearn: [
        'Mathematics preparation for the Navy exam',
        'English proficiency and comprehension skills',
        'General Knowledge, including current affairs, geography, and history',
        'Preparation for physical fitness and medical tests',
      ],
      benefits: [
        'Experienced trainers who specialize in Navy exams',
        'Comprehensive study material and practice tests',
        'Mock exams to simulate real exam conditions',
        'Personalized feedback on progress',
        'Expert guidance on physical fitness training',
      ],
    },
    police: {
      title: 'Delhi Police Exam Course',
      overview: 'Prepare for the Delhi Police recruitment exams with our structured course, which covers sections like Reasoning, General Knowledge, English, and Physical Fitness.',
      whatYouLearn: [
        'Reasoning, English, and General Knowledge preparation',
        'Physical Fitness training for the test',
        'Knowledge of the Delhi Police recruitment process',
        'Time management for the exam sections',
      ],
      benefits: [
        'Access to mock exams and quizzes',
        'Personalized mentorship and progress tracking',
        'Physical fitness tips and strategies for the test',
        'Comprehensive study materials and practice papers',
        'Dedicated doubt-solving sessions and feedback',
      ],
    },
    uppolice: {
      title: 'UP Police Exam Course',
      overview: 'Prepare for the UP Police recruitment exams with our structured course, which covers sections like Reasoning, General Knowledge, English, and Physical Fitness.',
      whatYouLearn: [
        'Reasoning, English, and General Knowledge preparation',
        'Physical Fitness training for the test',
        'Knowledge of the UP Police recruitment process',
        'Time management for the exam sections',
      ],
      benefits: [
        'Access to mock exams and quizzes',
        'Personalized mentorship and progress tracking',
        'Physical fitness tips and strategies for the test',
        'Comprehensive study materials and practice papers',
        'Dedicated doubt-solving sessions and feedback',
      ],
    },
    ota: {
      title: 'OTA Exam Course',
      overview: 'Prepare for the OTA recruitment exams with our structured course, which covers sections like General Knowledge, English, Reasoning, and Physical Fitness.',
      whatYouLearn: [
        'General Knowledge, English, and Reasoning preparation',
        'Physical Fitness training for the OTA exam',
        'Understanding of the OTA selection process',
        'Effective time management for the exam sections',
      ],
      benefits: [
        'Access to mock exams and quizzes',
        'Personalized mentorship and progress tracking',
        'Physical fitness tips and strategies for the OTA test',
        'Comprehensive study materials and practice papers',
        'Dedicated doubt-solving sessions and feedback',
      ],
    },
    capf: {
      title: 'CA-PF Exam Course',
      overview: 'Prepare for the CA-PF recruitment exams with our well-structured course, which covers subjects like General Knowledge, Reasoning, English, and Physical Fitness.',
      whatYouLearn: [
        'General Knowledge, Reasoning, and English preparation',
        'Physical Fitness training for the CA-PF test',
        'Insight into the CA-PF recruitment process',
        'Effective time management for the exam sections',
      ],
      benefits: [
        'Access to mock exams and quizzes',
        'Personalized mentorship and progress tracking',
        'Physical fitness tips and strategies for the CA-PF exam',
        'Comprehensive study materials and practice papers',
        'Dedicated doubt-solving sessions and feedback',
      ],
    },
    airforce: {
      title: 'Indian Air Force Exam Course',
      overview: 'Prepare for the Indian Air Force recruitment exams with our focused course, covering Mathematics, English, General Knowledge, and Physical Fitness.',
      whatYouLearn: [
        'Mathematics, English, and General Knowledge preparation',
        'Physical Fitness training for the Air Force recruitment test',
        'Understanding of the Indian Air Force selection process',
        'Time management for the exam sections',
      ],
      benefits: [
        'Access to mock exams and quizzes',
        'Personalized mentorship and progress tracking',
        'Physical fitness tips and strategies for the Air Force exam',
        'Comprehensive study materials and practice papers',
        'Dedicated doubt-solving sessions and feedback',
      ],
    },
    afcat: {
      title: 'AF-CAT Exam Course',
      overview: 'Prepare for the AF-CAT recruitment exams with our focused course, covering Mathematics, English, General Knowledge, and Physical Fitness.',
      whatYouLearn: [
        'Mathematics, English, and General Knowledge preparation',
        'Physical Fitness training for the Air Force recruitment test',
        'Understanding of the Indian Air Force selection process',
        'Time management for the exam sections',
      ],
      benefits: [
        'Access to mock exams and quizzes',
        'Personalized mentorship and progress tracking',
        'Physical fitness tips and strategies for the Air Force exam',
        'Comprehensive study materials and practice papers',
        'Dedicated doubt-solving sessions and feedback',
      ],
    },
  };

  // Fallback to a default course if courseType is not found
  const course = courseContent[courseType] || courseContent.nda;

  return (
    <div className="course-page">
      <h2 className="course-details-title">{course.title}</h2>
      <p className="course-intro">{course.overview}</p>

      <div className="course-section">
        <h3>What You'll Learn</h3>
        <ul>
          {course.whatYouLearn.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </div>

      <div className="course-section">
        <h3>Course Benefits</h3>
        <p>By enrolling in this course, you will receive the following benefits:</p>
        <ul>
          {course.benefits.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </div>


      <div className="enroll-button-course">
        <Link to="/enroll" className="enroll-link">
          <button className="enroll-btn">Enroll Now</button>
        </Link>
      </div>
    </div>
  );
};

export default CoursePage;
